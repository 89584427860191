var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-wizard",
    {
      ref: "wizard",
      attrs: {
        color: "rgba(var(--vs-warning), 1)",
        title: null,
        subtitle: null,
        finishButtonText: "Submit"
      },
      on: { "on-complete": _vm.formSubmitted }
    },
    [
      _c("tab-content", { staticClass: "mb-5", attrs: { title: "Step 1" } }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-5" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { label: "First Name" },
                model: {
                  value: _vm.firstName,
                  callback: function($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-5" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { label: "Last Name" },
                model: {
                  value: _vm.lastName,
                  callback: function($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-5" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { type: "email", label: "Email" },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-5" },
            [
              _c(
                "vs-select",
                {
                  staticClass: "w-full select-large",
                  attrs: { label: "City" },
                  model: {
                    value: _vm.city,
                    callback: function($$v) {
                      _vm.city = $$v
                    },
                    expression: "city"
                  }
                },
                _vm._l(_vm.cityOptions, function(item, index) {
                  return _c("vs-select-item", {
                    key: index,
                    staticClass: "w-full",
                    attrs: { value: item.value, text: item.text }
                  })
                }),
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("tab-content", { staticClass: "mb-5", attrs: { title: "Step 2" } }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full mt-4",
                attrs: { label: "Proposal Title" },
                model: {
                  value: _vm.proposalTitle,
                  callback: function($$v) {
                    _vm.proposalTitle = $$v
                  },
                  expression: "proposalTitle"
                }
              }),
              _c("vs-input", {
                staticClass: "w-full mt-4",
                attrs: { label: "Job Title" },
                model: {
                  value: _vm.jobTitle,
                  callback: function($$v) {
                    _vm.jobTitle = $$v
                  },
                  expression: "jobTitle"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full" },
            [
              _c("vs-textarea", {
                staticClass: "mb-0 md:mt-10 mt-6",
                attrs: { label: "Short discription", rows: "3" },
                model: {
                  value: _vm.textarea,
                  callback: function($$v) {
                    _vm.textarea = $$v
                  },
                  expression: "textarea"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("tab-content", { staticClass: "mb-5", attrs: { title: "Step 3" } }, [
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full mt-5",
                attrs: { label: "Event Name" },
                model: {
                  value: _vm.eventName,
                  callback: function($$v) {
                    _vm.eventName = $$v
                  },
                  expression: "eventName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full" },
            [
              _c(
                "vs-select",
                {
                  staticClass: "w-full select-large mt-5",
                  attrs: { label: "Event Location" },
                  model: {
                    value: _vm.city,
                    callback: function($$v) {
                      _vm.city = $$v
                    },
                    expression: "city"
                  }
                },
                _vm._l(_vm.cityOptions, function(item, index) {
                  return _c("vs-select-item", {
                    key: index,
                    staticClass: "w-full",
                    attrs: { value: item.value, text: item.text }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-5" },
            [
              _c(
                "vs-select",
                {
                  staticClass: "w-full select-large",
                  attrs: { label: "Event Status" },
                  model: {
                    value: _vm.status,
                    callback: function($$v) {
                      _vm.status = $$v
                    },
                    expression: "status"
                  }
                },
                _vm._l(_vm.statusOptions, function(item, index) {
                  return _c("vs-select-item", {
                    key: index,
                    staticClass: "w-full",
                    attrs: { value: item.value, text: item.text }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "vx-col md:w-1/2 w-full md:mt-8" }, [
            _c("div", { staticClass: "demo-alignment" }, [
              _c("span", [_vm._v("Requirements:")]),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("vs-checkbox", [_vm._v("Staffing")]),
                  _c("vs-checkbox", [_vm._v("Catering")])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }